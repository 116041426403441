import 'css/base/variables.css' // variaveis
import 'swiper/css/bundle'
import 'css/vendor/tippy-js.css'
import 'tippy.js/animations/shift-away-subtle.css'
import 'css/index.css' // entrada
import { DEV } from './js/main'

document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.add('DOMContentLoaded')
	DEV && document.body.classList.add('isDev')
	
})
